import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
	path: '/',
	component: () => import('../views/Home.vue'),
	children: [{
		path: '',
		name: 'meetlist',
		component: () => import('../views/d_meetlist/meetlist.vue'),
		meta: {
			title: '预定会议'
		}
	}, {
		path: 'meethistory',
		name: 'meethistory',
		component: () => import('../views/d_meetlist/meethistory.vue'),
		meta: {
			title: '历史会议'
		}
	}, {
		path: 'dmeetdetail',
		name: 'dmeetdetail',
		component: () => import('../views/d_meetdetail/meetdetail.vue'),
		meta: {
			title: '会议详情'
		}
	}, {
		path: 'livePage/:id?',
		name: 'livePage',
		component: () => import('../views/livePage/livePage.vue'),
		meta: {
			title: '直播页面'
		}
	}, {
		path: 'laowu',
		name: 'laowu',
		component: () => import('../views/d_laowu/laowu.vue'),
		meta: {
			title: '劳务'
		}
	}, {
		path: 'patientInfo/:id?',
		name: 'patientInfo',
		component: () => import('../views/patientInfo/patientInfo.vue'),
		meta: {
			title: '劳务详情'
		}
	}, {
		path: 'agreement',
		name: 'agreement',
		component: () => import('../views/agreement/agreement.vue'),
		meta: {
			title: '签署劳务协议'
		}
	}, {
		path: 'my',
		name: 'my',
		component: () => import('../views/my/my.vue'),
		meta: {
			title: '我的'
		}
	}, {
		path: 'changeInfo',
		name: 'changeInfo',
		component: () => import('../views/changeInfo/changeInfo.vue'),
		meta: {
			title: '修改个人信息'
		}
	}, {
		path: 'changepwd',
		name: 'changepwd',
		component: () => import('../views/changepwd/changepwd.vue'),
		meta: {
			title: '修改密码'
		}
	}, {
		path: 'project',
		name: 'project',
		component: () => import('../views/z_project/project.vue'),
		meta: {
			title: '项目列表'
		}
	}, {
		path: 'meetinglist',
		name: 'meetinglist',
		component: () => import('../views/z_meetlist/meetlist.vue'),
		meta: {
			title: '会议列表'
		}
	}, {
		path: 'meetdetails',
		name: 'meetdetails',
		component: () => import('../views/z_meetdetails/meetdetails.vue'),
		meta: {
			title: '会议详情'
		}
	}, {
		path: 'myproject',
		name: 'myproject',
		component: () => import('../views/d_myproject/myproject.vue'),
		meta: {
			title: '我的项目'
		}
	}, {
		path: 'createmeet',
		name: 'createmeet',
		component: () => import('../views/z_createmeet/createmeet.vue'),
		meta: {
			title: '会议操作'
		}
	}, {
		path: 'liveUrl',
		name: 'liveUrl',
		component: () => import('../views/z_liveurl/liveUrl.vue'),
		meta: {
			title: '预览会议链接与二维码'
		}
	}, {
		path: 'coverupload',
		name: 'coverupload',
		component: () => import('../views/z_coverupload/coverupload.vue'),
		meta: {
			title: '上传KV海报'
		}
	}, {
		path: 'confmeet',
		name: 'confmeet',
		component: () => import('../views/z_confmeet/confmeet.vue'),
		meta: {
			title: '确认会议信息'
		}
	}, {
		path: 'doctor',
		name: 'doctor',
		component: () => import('../views/z_doctor/doctor.vue'),
		meta: {
			title: '我的医生'
		}
	}, {
		path: 'doctorinfo',
		name: 'doctorinfo',
		component: () => import('../views/z_doctorinfo/doctorinfo.vue'),
		meta: {
			title: '医生详情'
		}
	}, {
		path: 'reviewdoctor',
		name: 'reviewdoctor',
		component: () => import('../views/z_doctor/reviewdoctor.vue'),
		meta: {
			title: '待审核医生'
		}
	}, {
		path: 'creatdoc',
		name: 'creatdoc',
		component: () => import('../views/z_creatdoc/creatdoc.vue'),
		meta: {
			title: '创建账号'
		}
	}, {
		path: 'perInfo',
		name: 'perInfo',
		component: () => import('../views/z_creatdoc/perInfo.vue'),
		meta: {
			title: '创建账号'
		}
	}, {
		path: 'upInfo',
		name: 'upInfo',
		component: () => import('../views/z_creatdoc/upInfo.vue'),
		meta: {
			title: '创建账号'
		}
	}, {
		path: 'selectPro',
		name: 'selectPro',
		component: () => import('../views/z_creatdoc/selectPro.vue'),
		meta: {
			title: '创建账号'
		}
	}, {
		path: 'option',
		name: 'option',
		component: () => import('../views/z_option/option.vue'),
		meta: {
			title: '操作'
		}
	}]
}, {
	path: '/login',
	name: 'login',
	component: () => import('@/views/login/login.vue'),
	meta: {
		title: '登录'
	}
}, {
	path: '/register',
	name: 'register',
	component: () => import('@/views/register/register.vue'),
	meta: {
		title: '注册'
	}
}, {
	path: '/ImproveInfo',
	name: 'ImproveInfo',
	component: () => import('@/views/register/ImproveInfo.vue'),
	meta: {
		title: '完善信息'
	}
}, {
	path: '/upLoadInfo',
	name: 'upLoadInfo',
	component: () => import('../views/register/UploadInfo.vue'),
	meta: {
		title: '上传照片'
	}
}, {
	path: '/setpwd',
	name: 'setpwd',
	component: () => import('@/views/register/setpwd.vue'),
	meta: {
		title: '设置密码'
	}
}, {
	path: '/SelectProject',
	name: 'SelectProject',
	component: () => import('@/views/register/SelectProject.vue'),
	meta: {
		title: '选择参与项目'
	}
}, {
	path: '/result',
	name: 'result',
	component: () => import('../views/register/result.vue'),
	meta: {
		title: ''
	}
}, {
	path: '/:pathMatch(.*)*',
	name: 'NotFound',
	component: () => import('../components/NotFound.vue'),
	meta: {
		title: '404'
	}
}];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	if (to.name != 'nopath') {
		if (to.meta.title) {
			document.title = `医咻智会-${to.meta.title}`;
		} else {
			document.title = `医咻智会`;
		};
		next();
	}
});

export default router